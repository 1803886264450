import React from 'react'
import { useIntl } from "gatsby-plugin-intl"
import oneStop from '../../images/one-stop.png'
import MoreIcon from '../../icons/more.svg'
import ColumnLines from 'src/components/ColumnLines'
//@ts-ignore
import * as Styles from './Service.module.scss'
import { identity } from 'lodash'


const Service = () => {
    const intl = useIntl()
    return (
        <div lang={intl.locale} className={Styles.main}>
            <ColumnLines />
            <div className={Styles.content}>
            {/* <ColumnLines /> */}
                <div className={Styles.imgBox}>
                    <img src={oneStop} />
                </div>
                <div className={Styles.oneStop}>
                    <MoreIcon />
                    <div>
                        <h2 lang={intl.locale}>{intl.formatMessage({ id: 'One Stop Service' })}</h2>
                        <div className={Styles.row}>
                            <div className={Styles.box}>
                                <h3 lang={intl.locale}>{intl.formatMessage({ id: 'DESIGN' })}</h3>
                                <p>{intl.formatMessage({ id: 'design description' })}</p>
                            </div>
                            <div className={Styles.box}>
                                <h3>{intl.formatMessage({ id: 'SUPPLY' })}</h3>
                                <p>{intl.formatMessage({ id: 'supply description' })}</p>
                            </div>
                            <div className={Styles.box}>
                                <h3>{intl.formatMessage({ id: 'INSTALLATION' })}</h3>
                                <p>{intl.formatMessage({ id: 'installation description' })}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={Styles.skew} />
            </div>

        </div>
    )
}

export default Service
