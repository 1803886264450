import React from 'react'
import { useIntl } from "gatsby-plugin-intl"
import MoreIcon from '../../icons/more.svg'
import iso1 from '../../images/iso1.png'
import iso2 from '../../images/iso2.png'
import iso3 from '../../images/iso3.png'
import ColumnLines from 'src/components/ColumnLines'
//@ts-ignore
import * as Styles from './Certificate.module.scss'

const Certificate = () => {
    const intl = useIntl()
    return (
        <div  className={Styles.main}
            lang={intl.locale}
        >
            <ColumnLines />
            <div className={Styles.content}>
                <div className={Styles.left}>
                    <MoreIcon />
                    <div className={Styles.textBox}>
                        <h2>{intl.formatMessage({ id: 'Certificates & Qualifications' })}</h2>
                        <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'certificates description' }) }} />
                    </div>
                </div>
                <div className={Styles.row}>
                    <div className={Styles.box}>
                        <img src={iso1} />
                        <div >ISO 9001:2015</div>
                        <div>{intl.formatMessage({ id: 'Quality Management System' })}</div>
                    </div>
                    <div className={Styles.box}>
                        <img src={iso2} />
                        <div >ISO 14001:2015</div>
                        <div>{intl.formatMessage({ id: 'Environmental Management System' })}</div>
                    </div>
                    <div className={Styles.box}>
                        <img src={iso3} />
                        <div>ISO 45001:2018</div>
                        <div>{intl.formatMessage({ id: 'Occupational Health and SafetyManagement System' })}</div>
                    </div>
                </div>
            </div>
            <div className={Styles.skew}/>
        </div>
    )
}

export default Certificate
