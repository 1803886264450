import React from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import Construction from './Construction'
import Service from './Service'
import ProjectHighlighDesktop from 'src/components/ProjectHighlighDesktop'
import ProjectMobile from './ProjectMobile'
import Certificate from './Certificate'
import GetInTouch from '../GetInTouch'

//@ts-ignore
import * as Styles from './HomePage.module.scss';
const HomePage = () => {
    const breakpoints = useBreakpoint()
    return (
        <div className={Styles.main}>
            <Construction />
            <Service />
            <Certificate />
            {
                 breakpoints.sm ?
                <ProjectMobile /> : <ProjectHighlighDesktop />
            }
            <GetInTouch />
        </div>
    )
}

export default HomePage
