import * as React from "react"
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { useIntl } from "gatsby-plugin-intl"

import HomePage from '../components/home/HomePage'
import NavBar from '../components/NavBar'
//@ts-ignore
import * as Styles from './index.module.scss'
import Footer from '../components/Footer'


const IndexPage = ({ data }: { data: any }) => {
  const intl = useIntl()

  return (<>
    <Helmet>
      <title>{intl.formatMessage({ id: data.site.siteMetadata.title })}</title>
      <meta
        name="google-site-verification"
        content={"FzHToZTcbm9Do-3zTA3titoMvaMYmcROOa2lvU0gDT0"}
      />
      <meta
        name="description"
        content={data.site.siteMetadata.description}
      />
      <meta
        name="keywords"
        content={data.site.siteMetadata.keywords}
      />
    </Helmet>
    <div
     
      className={Styles.main}

    >
      <NavBar />
      <HomePage />
      <Footer />
    </div>
  </>)
}
export const query = graphql`
    query IndexQuery {
        site {
            siteMetadata {
                title
                description
                keywords
            }
        }
    }
`;

export default IndexPage

{/* <Layout>
<Seo title="Home" />
<h1>Hi people</h1>
<p>Welcome to your new Gatsby site.</p>
<p>Now go build something great.</p>
<StaticImage
  src="../images/gatsby-astronaut.png"
  width={300}
  quality={95}
  formats={["AUTO", "WEBP", "AVIF"]}
  alt="A Gatsby astronaut"
  style={{ marginBottom: `1.45rem` }}
/>
<p>
  <Link to="/page-2/">Go to page 2</Link> <br />
  <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
</p>
<App />
</Layout> */}