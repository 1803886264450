import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import * as _ from 'lodash'
import { projects } from 'src/projectData.store'
import MoreIcon from '../../icons/more.svg'
import tkoToLTTunnelCover from '../../images/tseung-kwan-o-lam-tin-tunnel-cover.png'
//@ts-ignore
import * as  Styles from './ProjectMobile.module.scss'
import YearBox from '../YearBox'
const ProjectMobile = () => {
    const intl = useIntl()
    return (
        <div lang={intl.locale} className={Styles.main}>
            <div className={Styles.content}>
                <div className={Styles.title}>
                    <MoreIcon />
                    <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'Project Highlight2' }) }} />
                </div>
                <div className={Styles.right}>
                    <div className={Styles.row}>
                        {
                            projects.filter(item => item.feature).map(item => <a href={`/project-detail/?projectId=${item.projectId}`}>
                                <div className={Styles.box}>
                                    <img src={_.head(item.imsrc)} />
                                    <h3>{intl.formatMessage({ id: item.nameId })}
                                        <YearBox period={intl.formatMessage({ id: item.periodId })} />
                                    </h3>
                                </div>
                            </a>)
                        }
                    </div>
                    <a
                        href={'/project'}
                    ><div className={Styles.our}>
                            <p>{intl.formatMessage({ id: 'Our Projects' })}</p>
                            <MoreIcon />
                        </div></a>
                </div>
            </div>
        </div>
    )
}

export default ProjectMobile
