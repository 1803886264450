import React, { useState, useEffect } from 'react'
import { useIntl } from "gatsby-plugin-intl"
import Classnames from 'classnames'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import banner3 from '../../images/banner3.png'
import banner2 from '../../images/banner2.png'
import banner1 from '../../images/banner1.png'
import MoreIcon from '../../icons/more.svg'
// @ts-ignore
import * as Styles from './Construction.module.scss'


const Construction = () => {
    const breakpoints = useBreakpoint()
    const intl = useIntl()
    const [num, setNum] = useState(2)

    setTimeout(() => {
        if (num === 2) setNum(0)
        if (num === 0) setNum(1)
        if (num === 1) setNum(2)
    }, 9000)

    useEffect(() => {
        setNum(0)
    }, [])
    return (
        <div  lang={intl.locale} className={Styles.main}>
            <div className={Styles.inner}>
                <div className={Styles.content}>
                    <div className={Styles.textBox}>
                        <MoreIcon />
                        <div className={Styles.inner}>
                            {
                                (intl.locale === 'en' && breakpoints.sm) ? <h1
                                    lang={intl.locale}
                                >
                                    Construction Professional
                                </h1> : <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'Construction Professional' }) }} />
                            }
                            <p lang={intl.locale}>
                                <div lang={intl.locale} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'construction description' }) }} />
                            </p>
                            <a href={'/project'}>
                                <div className={Styles.our}>
                                    <p lang={intl.locale}>{intl.formatMessage({ id: 'Our Projects' })}</p>
                                    <MoreIcon />
                                </div>
                            </a>
                        </div>
                    </div>
                    {
                        !breakpoints.sm ? <>
                            <div className={Styles.imgBox}>
                                {num === 0 && <img src={banner1} />}
                                {num === 1 && <img src={banner2} />}
                                {num === 2 && <img src={banner3} className={Styles.banner3} />}
                            </div>
                            <div className={Styles.barBox}>
                                <div className={Styles.element}>
                                    <div className={Classnames(Styles.bar, { [Styles.active]: num === 0 })}>
                                        <div className={Styles.redBar} />
                                        {num === 1 && <div className={Styles.hideRed} />}
                                    </div>

                                </div>
                                <div className={Styles.element}>
                                    <div className={Classnames(Styles.bar, { [Styles.active]: num === 1 })}>
                                        <div className={Styles.redBar} />
                                        {num === 2 && <div className={Styles.hideRed} />}
                                    </div>
                                </div>
                                <div className={Styles.element}>
                                    <div className={Classnames(Styles.bar, { [Styles.active]: num === 2 })}>
                                        <div className={Styles.redBar} />
                                        {num === 0 && <div className={Styles.hideRed} />}
                                    </div>
                                </div>
                            </div>
                            <div className={Styles.barTextBox}>
                                <div
                                    lang={intl.locale}
                                    className={Classnames(Styles.element, {
                                        [Styles.active]: num === 0
                                    })}>
                                    {intl.formatMessage({ id: '01 Design' })}
                                </div>
                                <div
                                    lang={intl.locale}
                                    className={Classnames(Styles.element, {
                                        [Styles.active]: num === 1
                                    })}>
                                    {intl.formatMessage({ id: '02 Supply' })}
                                </div>
                                <div
                                    lang={intl.locale}
                                    className={Classnames(Styles.element, {
                                        [Styles.active]: num === 2
                                    })}>
                                    {intl.formatMessage({ id: '03 Installation' })}
                                </div>
                            </div>
                        </> : <div className={Styles.imgMobile}>
                                {num === 0 && <img src={banner1} />}
                                {num === 1 && <img src={banner2} />}
                                {num === 2 && <img src={banner3} className={Styles.banner3} />}
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Construction
