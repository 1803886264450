// extracted by mini-css-extract-plugin
export var main = "Construction-module--main--zpuA1";
export var inner = "Construction-module--inner--31LcW";
export var content = "Construction-module--content--12L3g";
export var textBox = "Construction-module--textBox--29GoM";
export var our = "Construction-module--our--2VGX1";
export var imgBox = "Construction-module--imgBox--2-WWZ";
export var fade = "Construction-module--fade--7b5VG";
export var barBox = "Construction-module--barBox--YR2SD";
export var element = "Construction-module--element--2OSIr";
export var bar = "Construction-module--bar--2xq_2";
export var redBar = "Construction-module--redBar--3yiY8";
export var hideRed = "Construction-module--hideRed--3QS-D";
export var active = "Construction-module--active--2FzT7";
export var barTextBox = "Construction-module--barTextBox--2sGU7";
export var imgMobile = "Construction-module--imgMobile--2bK55";
export var banner3 = "Construction-module--banner3--FVEs5";